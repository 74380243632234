import Layout from "../../components/Layout";
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

export default () => {
  const title = "Resources for Family and Educators | Schoolhouse by the Sea";
  const description =
    "We have a wide variety of family and educator resources available.";
  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
      </Helmet>
      <section className="section">
        <div className="container">
          <div className="content">
            <h1 className="title">Welcome Families and Educators!</h1>
            <p>
              Here you will find all the resources we've developed and curated
              to support your educating initiatives. We will be posting updates
              at least 3 times a week to ensure you have fresh material at your
              disposal. Our releases will be out Sunday, Wednesday, and Friday
              mornings. Have a wonderful school year!
            </p>
          </div>
          <div className="content">
            <h2>
              <Link to="/educators/chalk-drawing">Chalk Drawing</Link>
            </h2>
            <h2>
              <Link to="/educators/family-enrichment">Family Enrichment</Link>
            </h2>
            <h2>
              <Link to="/educators/planning-resources">Planning Resources</Link>
            </h2>
            <h2>
              <Link to="/educators/recommendations">Recommendations</Link>
            </h2>
            <h2>
              <Link to="/educators/student-materials-guide">
                Student Materials Guide
              </Link>
            </h2>
          </div>
          <div className="content">
            <div className="is-size-4">
              Resource Categories Coming Soon Include:
            </div>
            <ul>
              {/*<h2>*/}
              {/*  <Link to="/educators/inspiration">*/}
              <li className="is-size-5">Inspiration</li>
              {/*  </Link>*/}
              {/*</h2>*/}
              {/*<h2>*/}
              {/*  <Link to="/educators/tips-and-trick">*/}
              <li className="is-size-5">Tips and Tricks</li>
              {/*  </Link>*/}
              {/*</h2>*/}
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  );
};
